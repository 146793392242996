import * as React from 'react';
import { DndProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { DriftUser, User } from 'types/interfaces';

import { fetchVideoUserForDriftUser } from 'api';
import FullPageSpinner from 'components/fullPageSpinner';
import NavigationSidebar from 'components/navigationSidebar';
import UploadApp from 'components/upload/UploadApp';
import FolderProvider from 'context/FolderProvider';
import UserProvider, { UserContext } from 'context/UserContext';
import UserCustomizationsProvider from 'context/UserCustomizationContext';
import OnboardingApp from 'layouts/onboarding';
import ReportsApp from 'layouts/reports/ReportsApp';
import SettingsApp from 'layouts/settings/SettingsApp';
import SharePage from 'layouts/sharePage';
import VideoLibrary from 'layouts/videoLibrary/VideoLibraryApp';

import './styles.css';

const { useEffect, useContext, useState } = React;

const App = () => {
  const { appLoading } = useContext(UserContext);

  if (appLoading) {
    return <FullPageSpinner show={true} hideBlur={true} colorMode="dark" />;
  }

  return (
    <Router basename="/">
      <Switch>
        {/*@ts-ignore*/}
        <DndProvider backend={HTML5Backend}>
          <FolderProvider>
            <NavigationSidebar />
            <Route path="/video">
              <VideoLibrary />
            </Route>
            <Route path="/video/share/:id">
              <SharePage />
            </Route>
            <Route path="/video/reports">
              <ReportsApp />
            </Route>
            <Route path="/video/onboarding">
              <OnboardingApp />
            </Route>
            <Route path="/video/upload">
              <UploadApp />
            </Route>
            <Route path="/video/settings">
              <UserCustomizationsProvider>
                <SettingsApp />
              </UserCustomizationsProvider>
            </Route>
          </FolderProvider>
        </DndProvider>
      </Switch>
    </Router>
  );
};

const mapStateToProps = (state: any) => ({
  driftUser: state.user,
});

const WrappedApp = ({ driftUser }: { driftUser: DriftUser }) => {
  const [userCreated, setUserCreated] = useState<boolean>(false);
  const [videoUser, setVideoUser] = useState<User | undefined>();

  useEffect(() => {
    const getVideoUserForDriftUser = async () => {
      try {
        const result = await fetchVideoUserForDriftUser();
        setVideoUser(result);
      } catch (e) {
        throw new Error((e as any).message);
      } finally {
        setUserCreated(true);
      }
    };

    (async function () {
      await getVideoUserForDriftUser();
    })();
  }, []);
  if (!userCreated) {
    return <FullPageSpinner show={true} hideBlur={true} colorMode="dark" />;
  }

  return (
    <UserProvider maybeVideoUser={videoUser} maybeDriftUser={driftUser}>
      <App />
    </UserProvider>
  );
};

export default connect(mapStateToProps, {})(WrappedApp);
